import { useState, useEffect } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { RowsPhotoAlbum } from "react-photo-album";
import "react-photo-album/rows.css";

const ImageGrid = (props) => {
  
  const [index, setIndex] = useState(-1);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [props.images]);

  return (
    <div>
      <RowsPhotoAlbum 
        photos={props.images} 
        onClick={({ index }) => {
          setIndex(index);
        }}
        spacing={0}
      />
      <Lightbox
        index={index}
        slides={props.images}
        open={index >= 0}
        close={() => setIndex(-1)}
        carousel={{imageFit: "contain"}}
      />
    </div>
  );
}

export default ImageGrid;
