import './App.css';
import Header from './Header';
import Footer from './Footer';
import ImageGrid from './ImageGrid';
import { BrowserRouter, Routes, Route, Navigate, Link } from 'react-router-dom';
import { images, sportsImages } from "./images.js"
import styles from "./joshua.module.css"


function App() {
  return (
    <>
        <BrowserRouter basename={`/${process.env.PUBLIC_URL}`}>
          <Header></Header>
          <Routes>
            <Route path="" element={
              <>
                <ImageGrid images={images}/>
                <Footer link={<Link to="/sports" className={styles.footerLink}>Sports</Link>}></Footer>
              </>
            } />
            <Route path="/sports" element={
              <>
                <ImageGrid images={sportsImages}/>
                <Footer link={<Link to="/" className={styles.footerLink}>Home</Link>}></Footer>
              </>
            } />
            <Route path="*" element={<Navigate to="" />} />
          </Routes>
          
        </BrowserRouter>
    </>
  );
}

export default App;
